import React, { useContext } from "react"

export interface SignUpDestinationHandlerContextState {
  uri: string
}

export const SignUpDestinationHandlerContext = React.createContext<
  SignUpDestinationHandlerContextState
>({
  uri: "",
})

export const useSignUpDestinationUri = (): string => {
  return useContext(SignUpDestinationHandlerContext).uri
}

const SignUpDestinationHandler: React.FC<{
  uri: string
}> = ({ children, uri }) => (
  <SignUpDestinationHandlerContext.Provider value={{ uri }}>
    {children}
  </SignUpDestinationHandlerContext.Provider>
)

export default SignUpDestinationHandler
