import React from "react"
import styled from "styled-components"
import { VodStateView } from "../VodLoadingView/VodLoadingView"

const StyledButton = styled.button`
  color: inherit;
  text-decoration: underline;
`

const VodErrorView: React.FC = () => {
  const handleRefresh = () => {
    window.location.reload()
  }
  return (
    <VodStateView
      smallerTitle
      title="An error occurred"
      description={
        <StyledButton onClick={handleRefresh} type="button">
          Refresh
        </StyledButton>
      }
    />
  )
}

export default VodErrorView
