import React from "react"
import styled from "styled-components"
import theme from "../../../../ui/theme"
import YSLogo from "../../../../assets/inline-assets/youspired_mark_large_coral.svg"

const StyledContainer = styled.div<{
  smallerTitle: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    font-size: ${props => (props.smallerTitle ? "32" : "48")}px;
    font-weight: ${theme.fontWeights.bold};
    line-height: 1;
    margin-top: 24px;
    margin-bottom: 18px;
  }
`

const StyledLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 100px;
    height: auto;
  }
`

export const VodStateView: React.FC<{
  smallerTitle?: boolean
  title: string
  description: any
}> = ({ smallerTitle = false, title, description }) => (
  <StyledContainer smallerTitle={smallerTitle}>
    <div>
      <StyledLogo>
        <YSLogo />
      </StyledLogo>
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  </StyledContainer>
)

const VodLoadingView: React.FC = () => (
  <VodStateView title="Loading..." description="Ready to be Youspired?" />
)

export default VodLoadingView
