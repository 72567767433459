import { useEffect, useState } from "react"
import { get } from "lodash"
import { firebaseDatabase } from "../../firebase/client"
import { getVideoThumbnailRaw } from "../../firebase/video"

export interface IFirestoreVideo {
  coachId: string
  videoId: string
  playbackId: string
  thumbnailOffset: number
  title: string
  topic: string
  muxCreatedAt: number
}

export const getVodTitle = (vod: IFirestoreVideo): string => {
  return get(vod, "title", "") || ""
}

export const getVodVideoId = (vod: IFirestoreVideo): string => {
  return get(vod, "videoId", "") || ""
}

export const getVodVideoTopic = (vod: IFirestoreVideo): string => {
  return get(vod, "topic", "") || ""
}

export const getVodVideoSavedDate = (vod: IFirestoreVideo): string => {
  const timestamp = get(vod, "muxCreatedAt", 0) || 0
  return new Date(timestamp).toDateString()
}

export const getVodThumbnail = (vod: IFirestoreVideo): string => {
  const { playbackId, thumbnailOffset } = vod
  return getVideoThumbnailRaw(playbackId, thumbnailOffset)
}

export const vodsRef = firebaseDatabase.collection("videos")

export const fetchFirestoreVod = (videoId: string) => {
  const videoRef = vodsRef.doc(videoId)
  return videoRef
    .get()
    .then(doc => {
      if (doc.exists) {
        // console.log("Document data:", doc.data())
        const data = doc.data()
        return data as IFirestoreVideo
      }
      // doc.data() will be undefined in this case
      // console.log("No such document!")
      throw new Error("No such document")
    })
    .catch(error => {
      console.error("Error getting document:", error)
      Sentry.captureMessage("Something went wrong when fetching firestore vod")
      Sentry.captureException(error)
      throw error
    })
}

export const useFetchFirestoreVod = (
  videoId: string
): [IFirestoreVideo | undefined, boolean, any] => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>(undefined)
  const [data, setData] = useState<IFirestoreVideo | undefined>(undefined)

  useEffect(() => {
    fetchFirestoreVod(videoId)
      .then((response: IFirestoreVideo | undefined) => {
        setData(response)
      })
      .catch(fetchError => {
        Sentry.captureException(fetchError)
        setError(fetchError)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return [data, loading, error]
}
