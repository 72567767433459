import React, { useContext } from "react"
import { ICMSCoachMin } from "../../../../data/cms/coach"
import { IFirestoreVideo } from "../../../../data/vod/firestore"

export interface VodDataHandlerContextState {
  coachData: ICMSCoachMin | undefined
  vodData: IFirestoreVideo | undefined
}

export const VodDataHandlerContext = React.createContext<
  VodDataHandlerContextState
>({
  coachData: undefined,
  vodData: undefined,
})

export const useVodDataHandlerContext = (): VodDataHandlerContextState => {
  return useContext(VodDataHandlerContext)
}

export const useVodData = (): IFirestoreVideo | undefined => {
  return useVodDataHandlerContext().vodData
}

export const useVodCoachData = (): ICMSCoachMin | undefined => {
  return useVodDataHandlerContext().coachData
}

const VodDataHandler: React.FC<{
  coachData: ICMSCoachMin | undefined
  vodData: IFirestoreVideo | undefined
}> = ({ children, coachData, vodData }) => (
  <VodDataHandlerContext.Provider value={{ coachData, vodData }}>
    {children}
  </VodDataHandlerContext.Provider>
)

export default VodDataHandler
