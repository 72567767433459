import React from "react"
import styled from "styled-components"
import SignUpPrompt from "../../../../components/SignUpPrompt/SignUpPrompt"
import { useVodCoachData, useVodData } from "../VodDataHandler/VodDataHandler"
import { getVodThumbnail } from "../../../../data/vod/firestore"
import {
  getCoachMinPossessiveName,
  getCoachMinSlug,
  getCoachMinSubscriptionPrice,
} from "../../../../data/cms/coach"
import { useSignUpDestinationUri } from "../../../../components/SignUpDestinationHandler/SignUpDestinationHandler"

const StyledContainer = styled.div<{
  image: string
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-image: url(${props => props.image});
`

const VodSignUpView: React.FC = () => {
  const coachData = useVodCoachData()
  const vodData = useVodData()
  const destinationUri = useSignUpDestinationUri()

  const backgroundImage = vodData ? getVodThumbnail(vodData) : ""
  const name = coachData ? getCoachMinPossessiveName(coachData) : ""

  return (
    <StyledContainer image={backgroundImage}>
      <SignUpPrompt
        destination={destinationUri}
        disableClose
        includeLogo
        opaque
        name={name}
        coachId={coachData?.coachId}
        coachSlug={coachData ? getCoachMinSlug(coachData) : ""}
        coachPrice={coachData ? getCoachMinSubscriptionPrice(coachData) : ""}
      />
    </StyledContainer>
  )
}

export default VodSignUpView
