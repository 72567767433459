import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "@reach/router"
import { useFetchVodData } from "../../data/vods"
import VodOverlay from "./components/VodOverlay/VodOverlay"
import VodDataHandler from "./components/VodDataHandler/VodDataHandler"
import SignUpDestinationHandler from "../../components/SignUpDestinationHandler/SignUpDestinationHandler"
import { useIsCoachSubscribedTo } from "../../auth/components/AuthHandler/hooks"
import { getCoachMinSlug } from "../../data/cms/coach"

type Props = RouteComponentProps<{
  coachId: string
  videoId: string
}>

const VodScreen: React.FC<Props> = ({ coachId, videoId, uri }) => {
  const [paramsError, setParamsError] = useState(false)

  if (!coachId) {
    console.error(`Coach Id not provided.`)
    setParamsError(true)
  }
  if (!videoId) {
    console.error(`Video Id not provided.`)
    setParamsError(true)
  }
  const [isLoading, isError, coachData, vodData] = useFetchVodData(
    coachId || "",
    videoId || ""
  )

  const [isSubscribed] = useIsCoachSubscribedTo(coachId || "")

  // console.log("isSubscribed", isSubscribed)

  return (
    <SignUpDestinationHandler uri={uri || ""}>
      <VodDataHandler coachData={coachData} vodData={vodData}>
        <VodOverlay
          coachId={coachId || ""}
          coachSlug={coachData ? getCoachMinSlug(coachData) : ""}
          isLoading={isLoading}
          isError={isError || paramsError}
          isSubscribed={isSubscribed}
        />
      </VodDataHandler>
    </SignUpDestinationHandler>
  )
}

export default VodScreen
