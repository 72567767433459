import React from "react"
import { Router } from "@reach/router"
import VodScreen from "../screens/VodScreen/VodScreen"
import { ROUTES } from "../navigation/routes"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import PageLocation from "../analytics/components/PageLocation/PageLocation"

const Vods: React.FC = () => (
  <SiteWrapper darkTheme>
    <SiteLayout>
      <PageLocation pageName={ROUTES.vods.name}>
        <Router basepath={ROUTES.vods.path}>
          <VodScreen path="/:coachId/:videoId" />
        </Router>
      </PageLocation>
    </SiteLayout>
  </SiteWrapper>
)

export default Vods
