import { useQuery } from "@apollo/react-hooks"
import { FETCH_COACH, ICMSCoachMin } from "./cms/coach"
import { IFirestoreVideo, useFetchFirestoreVod } from "./vod/firestore"
import {
  isCmsDevEnv,
  isCmsPrdEnv,
  isCmsStgEnv,
  isCmsUatEnv,
} from "../utils/env"

interface CoachQueryData {
  coachPrd?: ICMSCoachMin
  coachUat?: ICMSCoachMin
  coachStg?: ICMSCoachMin
  coachDev?: ICMSCoachMin
}

export const useFetchVodData = (
  coachId: string,
  videoId: string
): [
  boolean,
  boolean,
  ICMSCoachMin | undefined,
  IFirestoreVideo | undefined
] => {
  const { loading, error, data } = useQuery<
    CoachQueryData,
    {
      coachId: string
      devEnv: boolean
      stgEnv: boolean
      uatEnv: boolean
      prdEnv: boolean
    }
  >(FETCH_COACH, {
    variables: {
      coachId,
      devEnv: isCmsDevEnv(),
      stgEnv: isCmsStgEnv(),
      uatEnv: isCmsUatEnv(),
      prdEnv: isCmsPrdEnv(),
    },
  })
  const [vodData, loadingVod, vodError] = useFetchFirestoreVod(videoId)

  const isLoading = loading || loadingVod
  const isError = !!error || !!vodError

  let coachData: ICMSCoachMin | undefined

  if (data) {
    if (isCmsDevEnv()) {
      if (data?.coachDev) {
        coachData = data.coachDev as ICMSCoachMin
      }
    } else if (data?.coachStg) {
      coachData = data.coachStg as ICMSCoachMin
    } else if (data?.coachUat) {
      coachData = data.coachUat as ICMSCoachMin
    } else if (data?.coachPrd) {
      coachData = data.coachPrd as ICMSCoachMin
    }
  }

  return [isLoading, isError, coachData, vodData]
}
