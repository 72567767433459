/* eslint-disable no-nested-ternary */
import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import theme from "../../../../ui/theme"
import VodSignUpView from "../VodSignUpView/VodSignUpView"
import VodLoadingView from "../VodLoadingView/VodLoadingView"
import { useAuthHandlerContext } from "../../../../auth/components/AuthHandler/AuthHandler"
import VodPlayView from "../VodPlayView/VodPlayView"
import ArrowLeftIcon from "../../../../assets/inline-assets/arrowLeft.svg"
import { ROUTES } from "../../../../navigation/routes"
import VodErrorView from "../VodErrorView/VodErrorView"
import { useOnCoachClick } from "../../../../navigation/hooks"

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.zIndexes.videoOverlay};
  background: ${theme.colors.darkGrey};
  color: ${theme.colors.white};
`

const StyledBackButton = styled.button`
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: ${theme.zIndexes.videoOverlay + 10};

  svg {
    path {
      stroke: ${theme.colors.white};
    }
  }
`

const VodOverlay: React.FC<{
  coachId: string
  coachSlug: string
  isSubscribed: boolean
  isLoading: boolean
  isError: boolean
}> = ({ coachId, coachSlug, isLoading, isSubscribed, isError }) => {
  const { authenticated } = useAuthHandlerContext()
  const onCoachClick = useOnCoachClick()
  let view = <VodLoadingView />
  if (isError) {
    view = <VodErrorView />
  } else if (!isLoading) {
    if (!authenticated || !isSubscribed) {
      view = <VodSignUpView />
    } else {
      view = <VodPlayView />
    }
  }
  return (
    <>
      <StyledContainer>{view}</StyledContainer>
      <StyledBackButton
        as={Link}
        to={ROUTES.coach.paramPath(coachSlug)}
        onClick={() => onCoachClick(coachId)}
      >
        <ArrowLeftIcon />
      </StyledBackButton>
    </>
  )
}

export default VodOverlay
