import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled, { css } from "styled-components"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import { useVodCoachData, useVodData } from "../VodDataHandler/VodDataHandler"
import {
  mixpanelEventEndVideo,
  mixpanelEventStartVideo,
  mixpanelEventWatchVideoHalf,
} from "../../../../analytics/mixpanel"
import { getCoachMinName, ICMSCoachMin } from "../../../../data/cms/coach"
import {
  getVodTitle,
  getVodVideoId,
  getVodVideoSavedDate,
  getVodVideoTopic,
  IFirestoreVideo,
} from "../../../../data/vod/firestore"
import VodErrorView from "../VodErrorView/VodErrorView"
import {
  gaEventVideosCoach,
  gaEventVideosStartVideo,
  gaEventVideosTopic,
  gaEventVideosWatchVideoHalf,
} from "../../../../analytics/google"
import { incrementVideoViewCount } from "../../../../firebase/video"

const StyledWrapper = styled.div`
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
`

const videoClass = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const VodPlayView: React.FC = () => {
  const mixpanel = useMixpanel()
  const [videoDuration, setVideoDuration] = useState(0)
  const [videoError, setVideoError] = useState<any>()
  const [passedHalfway, setPassedHalfway] = useState(false)
  const vodData = useVodData() as IFirestoreVideo
  const coachData = useVodCoachData() as ICMSCoachMin
  const playbackId = vodData ? vodData.playbackId : ""
  const url = playbackId ? `https://stream.mux.com/${playbackId}.m3u8` : ""
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const coachName = getCoachMinName(coachData)
  const videoTitle = getVodTitle(vodData)
  const videoId = getVodVideoId(vodData)
  const topic = getVodVideoTopic(vodData)
  const savedDate = getVodVideoSavedDate(vodData)
  const onStart = () => {
    incrementVideoViewCount(videoId)
    mixpanelEventStartVideo(
      mixpanel,
      coachName,
      videoTitle,
      videoId,
      topic,
      savedDate
    )
    gaEventVideosStartVideo(videoTitle, videoId, 0)
    gaEventVideosCoach(coachName)
    gaEventVideosTopic(topic)
  }
  const onEnded = () => {
    mixpanelEventEndVideo(
      mixpanel,
      coachName,
      videoTitle,
      videoId,
      topic,
      savedDate
    )
  }
  const onError = (error: any, data: any) => {
    const fatal = !(data && !data.fatal) // fatal by default if relevant data is missing
    if (fatal) {
      Sentry.captureMessage("Fatal video playback error")
      setVideoError(true)
    } else {
      Sentry.captureMessage("Non-fatal video playback error")
    }
    Sentry.captureException(error)
    // Sentry.captureException(data)
  }
  const onProgress = ({ played }: { played: number }) => {
    if (played > 0.5) {
      setPassedHalfway(true)
    }
  }

  const onDuration = (duration: number) => {
    setVideoDuration(duration)
  }

  useEffect(() => {
    if (passedHalfway) {
      mixpanelEventWatchVideoHalf(
        mixpanel,
        coachName,
        videoTitle,
        videoId,
        topic,
        savedDate
      )
      gaEventVideosWatchVideoHalf(videoTitle, videoId, videoDuration / 2)
    }
  }, [passedHalfway])

  useEffect(() => {
    return () => {
      mixpanelEventEndVideo(
        mixpanel,
        coachName,
        videoTitle,
        videoId,
        topic,
        savedDate
      )
    }
  }, [])

  if (videoError) {
    return <VodErrorView />
  }
  return (
    <StyledWrapper>
      <ReactPlayer
        className={videoClass}
        url={url}
        config={{
          file: {
            forceHLS: !isSafari,
            forceVideo: true,
            hlsVersion: "0.12.4",
          },
        }}
        controls
        width="100%"
        height="100%"
        onStart={onStart}
        onEnded={onEnded}
        onError={onError}
        onProgress={onProgress}
        onDuration={onDuration}
      />
    </StyledWrapper>
  )
}

export default VodPlayView
